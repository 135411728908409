import axios from 'axios';
import { Feedback } from '../../models/feedback';

export class FeedbackService {
  private endpoint: string;

  constructor() {
    this.endpoint = 'https://ournationexpress.com/api/v1/feedback';
  }

  async createFeedback(feedback: Feedback) {
    return axios
      .post<Feedback>(`${this.endpoint}/`, feedback)
      .then(({ data }: any) => data);
  }
}
