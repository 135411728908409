import kebabCase from 'lodash/kebabCase';
import VueRouter, { RouteConfig } from 'vue-router';

interface Meta {
  requiresAuth?: boolean;
  public?: boolean;

  label?: string;
  onlyWhenLoggedOut?: boolean;
}

export function trailingSlash(str: string) {
  return str.endsWith('/') ? str : str + '/';
}

export function redirect(redirect: () => string) {
  return { path: '*', redirect };
}

export interface RouteType extends Omit<RouteConfig, 'component'> {
  component: string;
}

export function route(
  path: RouteType['path'],
  name?: RouteType['name'],
  children?: RouteType['children'],
  meta?: Meta,
  redirect?: any,
  file?: string
) {
  const folder = (file || `${kebabCase(name)}`).toLowerCase();

  return {
    component: () => import(`@/views/${folder}/Index.vue`),
    name,
    path,
    meta: meta,
    children,
    redirect,
    props: true
  };
}

export function subRoute(config: Partial<RouteType>): RouteConfig {
  return {
    component: () => import(`@/${config.component}.vue`),
    name: config.name,
    path: trailingSlash(config.path!),
    meta: config.meta,
    children: config.children,
    redirect: config.redirect,
    props: config.props ?? true
  };
}

export function layout(
  path: string,
  name: string,
  children?: any[],
  redirect?: any,
  meta?: Meta
) {
  const folder = kebabCase(name);

  return {
    path,
    component: () => import(`@/layouts/${folder}/Index.vue`),
    props: true,
    children,
    redirect,
    meta
  };
}

export function root(children: any[]) {
  return [layout('', 'root', children)];
}

export function AuthGuard(router: VueRouter, tokenService: any) {
  router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const loggedIn = () => {
      try {
        return (
          !!tokenService.isExpired(
            tokenService.decodeToken(localStorage.getItem('access_token') || '')
              .exp
          ) && !!tokenService.getToken
        );
      } catch (e) {
        return false;
      }
    };

    if (requiresAuth) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!loggedIn()) {
        next({
          name: 'home',
          query: { redirect: to.fullPath }
        });
      } else {
        next();
        //add set refresh function
      }
    } else {
      next(); // make sure to always call next()!
    }
  });
}
