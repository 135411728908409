import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { Feedback } from '@/models/feedback';
import { FeedbackService } from '@/services/feedback';

const feedbackService = new FeedbackService();

export const state = {
  feedback: [] as Feedback[]
};

export type FeedbackState = typeof state;

export const mutations: MutationTree<FeedbackState> = {
  ADD_FEEDBACK(state: any, payload: Feedback) {
    state.feedback.push(payload);
  },

};

export const actions: ActionTree<FeedbackState, FeedbackState> = {

  createFeedback: async ({ commit }: any, payload: Feedback) => {
    return feedbackService.createFeedback(payload).then((model: Feedback) => {
      commit('ADD_FEEDBACK', model);
      return model;
    });
  }
};

export const getters: GetterTree<FeedbackState, FeedbackState> = {};
